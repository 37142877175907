<template>
  <div class="home">
    <section class="main-container">
      <div class="headline-wrapper">
        <div class="headline">Services</div>
        <div class="bio-container">
          <div class="block">
            <div class="block-title">Need an app coded?</div>
            <div class="block-text">
              I'm a full stack developer with a huge emphasis on user-centered
              design. Whether it's a dashboard that provides data driven
              analytics to your team or an app for your community, I build apps
              that scale with to your demand.
            </div>
          </div>
          <div class="block">
            <div class="block-title">MVP</div>
            <div class="block-text">
              I've developed and helped in delivering numerous MVP's in many
              different industries from digital insurance applications,
              marketplaces for creatives, to even my own businesses. I can help
              you go from idea to market to attract early adopters of your
              products.
            </div>
          </div>
          <div class="block">
            <div class="block-title">Chat or Mentorship</div>
            <div class="block-text">
              Let's chat! If you need help, want to talk career paths, cloud? I
              love meeting new people, hearing new ideas. You can book a time on
              <a
                class="calendar-link"
                target="_blank"
                href="https://calendly.com/manyworlds/30min"
                >calendly here!
              </a>
              Or just reach out to any of my socials.
            </div>
          </div>
          <div class="block">
            <div class="block-title">Strategies/Solutions</div>
            <div class="block-text">
              Many businesses and users who are growing may need to expand and
              scale there product. I can help you ensure the tools and
              technologies are used efficiently, evaluate infrastructure, and
              leverage the exciting technology that's here today.
            </div>
          </div>
        </div>
        <!-- Next steps -->
      </div>
      <!--Image overlay - underlay -->
      <div class="container">
        <img :src="brain" alt="brain" class="the-brain" />
        <div class="brain-overlay"></div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { BIO } from "@/configs/static_text.js";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      brain: require("../assets/brain.png"),
      bio: BIO
    };
  }
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap");
* {
  font-family: "Cardo", serif;
}
.main-container {
  position: relative;
  min-height: 100vh;
  margin: 0 10%;
}
.headline-wrapper {
  position: absolute;
  top: 15%;
  z-index: 999;
  width: 100%;
}
.headline {
  font-size: 3rem;
  color: #fff;
  font-weight: bold;
  text-align: left;
  text-decoration: underline;
}

.added-marg {
  margin-top: 100px;
}

.subtext {
  min-width: 600px;
  font-size: 2rem;
  color: #9c9c9c;
  font-weight: bold;
  text-align: left;
}

//image css here
.container {
  position: absolute;
  top: 20%;
  left: 40%;
  max-width: 600px;
}

/* Make the image to responsive */
.the-brain {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.brain-overlay {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.88;
}
.bio-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  .block {
    width: 40%;
    margin: 40px 10px 10px 10px;
    .block-title {
      font-size: 1.25rem;
      text-align: left;
      width: 100%;
      color: #9c9c9c;
      font-family: "Ubuntu", sans-serif;
    }
    .block-text {
      width: 100%;
      font-family: "Ubuntu", sans-serif;
      line-height: 150%;
      margin-top: 10px;
      text-align: left;
      color: #fff;
      .calendar-link {
        text-decoration: underline;
        color: #d07919;
        font-family: "Ubuntu", sans-serif;
      }
    }
  }
  // next section
  .card-row {
    margin: 50px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    .dev-block {
      background-color: #fff;
      text-align: left;
      width: 50%;
      margin: 10px;
      padding: 10px;
      min-height: 350px;
    }
  }
}

.bio-text {
  font-size: 1.25rem;
  color: #9c9c9c;
  font-weight: bold;
  text-align: left;
  max-width: 55%;
  margin-top: 20px;
}
@media only screen and (min-device-width: 350px) and (max-device-width: 736px) {
  .bio-container {
    flex-direction: column;
    width: 100%;
    flex-wrap: unset;
    margin-bottom: 50px;
    // margin: 10px;
    .block {
      width: 100%;
      margin: 40px 10px 0 10px;
      .block-title {
        font-size: 1.25rem;
        text-align: left;
        width: 100%;
        z-index: 999;
        color: #9c9c9c;
        font-family: "Nunito Sans", sans-serif;
      }
      .block-text {
        z-index: 999;
        width: 100%;
        font-family: "Nunito Sans", sans-serif;
        line-height: 150%;
        margin-top: 10px;
        text-align: left;
        font-size: 1rem;
        color: #fff;
        .calendar-link {
          text-decoration: underline;
          color: #d07919;
          font-family: "Ubuntu", sans-serif;
        }
      }
    }
  }
}
</style>
